<template>
  <div id="newsStoryContent">
    <img id="Image1" class="rounded img-large" src="@/assets/news-network/brickkeeper-looking-for-summer-fun.jpg">
    <p id="Text1">
      The LEGO Universe News Network has just received this transmission from Professor Brickkeeper:
      <br><br>
      <i>
        Dear Earth Friends,
        <br><br>
        I can only hope that the Maelstrom doesn't destroy this message before it reaches your world!
        The blasted dark menace is destroying our summertime fun!
        <br><br>
        We Minifigures should be lying on the beach, sipping soda in the bright sun! But how can we
        when the Maelstrom's power continues to grow?!
        The sand is swarming with Stromlings!
        <br><br>
        I'd give anything to get into my swim trunks-- and don't even talk to me about my tan!
        I'm almost as pale as my lab coat!
        <br><br>
        Help brighten my Universe with your inspiration!
        Head to the
        <router-link to="/community/creation-lab/challenge/23e389b0-e286-480a-bf33-e78a1301f15e">Creation Lab</router-link>
        to overcome the newest creative challenge: Build some LEGO Universe summer fun!
        <br>
      </i>
    </p>
  </div>
</template>
